import React from 'react';
import Header from '../../components/header/Header';

const CarnetAdresse = () => {
    return (
        <div>
            <Header/>
            <div className='textContent'>
            <p>Ceci est la page Contact - Carnet adresse</p>
            </div>
        </div>
    );
};

export default CarnetAdresse;