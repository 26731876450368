import React from 'react';
import Header from '../../components/header/Header';

const Calendrier = () => {
    return (
        <div>
            <Header/>

            <div className='textContent'>
            <p>Ceci est la page Événement - Calendrier anniversaire</p>
            </div>
        </div>
    );
};

export default Calendrier;