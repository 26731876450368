import React from 'react';
import "../index.css"
import Header from '../../components/header/Header';

const Jeux = () => {
    return (
        <div>
        <Header/>
        <div className='textContent'>
        <p>Ceci est la page JEUX</p>
        </div>
        </div>
    );
};

export default Jeux;