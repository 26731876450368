import React from 'react';
import "../index.css"
import Header from '../../components/header/Header';

const Moncompte = () => {
    return (
        <div>
        <Header/>
        <div className='textContent'>
        <p>Ceci est la page MON COMPTE</p>
        </div>
        </div>
    );
};

export default Moncompte;