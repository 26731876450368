import React from 'react';
import "../index.css"
import Header from '../../components/header/Header';

const Jador = () => {
    return (
        <div>
        <Header/>
        <div className='textContent'>
        <p>Ceci est la page JAD'OR</p>
        </div>
        </div>
    );
};

export default Jador;