import React from 'react';
import Header from '../../components/header/Header';

const Parameters = () => {
    return (
        <div>
            <Header/>
            <div className='textContent'>
            <p>ceci est la page des paramètres de l'utilisateur</p>
            </div>
        </div>
    );
};

export default Parameters;