import React from 'react';
import Header from '../../components/header/Header';

const Quiestce = () => {
    return (
        <div>
            <Header/>
            <div className='textContent'>
            <p>Ceci est la page Jeux - Qui est-ce ?</p>
            </div>
        </div>
    );
};

export default Quiestce;