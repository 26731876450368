import React from 'react';
import "../index.css"
import LogoConnexion from '../../components/logoConnexion/LogoConnexion';

const Hall = () => {
    return (
        <div>
            <LogoConnexion/>
        </div>
    );
};

export default Hall;